/* Table action css*/
.user-action-wrapper{
  @apply flex flex-wrap gap-4 items-center;
}

/* Individual User View drawer*/
/* header view of user profile */
.container-upperWrapper {
  @apply bg-theme-100  rounded-lg pt-2 pb-4;
  .container-skeleton-loading{
    @apply py-4 flex justify-center;
  }
  .container-upperWrapper-avatar {
    @apply p-2 flex justify-center ;
  }

  .container-upperWrapper-data-heading {
    @apply font-bold text-[20px] uppercase text-center pb-3 ;
    .edit-outlined {
      @apply cursor-pointer text-2xl pl-2 text-Ascent2;
    }
  }

  .container-upperWrapper-data {
    @apply pt-2 mt-2;
    p {
      @apply text-xl font-medium flex justify-between px-10 flex-wrap items-center ;
      .upper-icons {
        @apply  w-[18px] pt-3
      }
      .edit-outlined {
        @apply cursor-pointer text-2xl pl-2 text-Ascent2;
      }
    }
  }
}
/* footer view of user profile */
.container-lowerWrapper {
  @apply p-2 bg-[#fff] mt-5 rounded-lg overflow-auto border;
  h3 {
    @apply uppercase font-bold text-center p-2 text-2xl
  }

  .container-lowerWrapper-content {
    @apply text-xl flex justify-between mx-4 flex-wrap items-center py-3 bg-theme-50 my-3 px-4 rounded-lg;
    .edit-outlined {
      @apply cursor-pointer text-2xl pl-2 text-Ascent2;
    }
  }
  .lowerWrapper-bio-container{
    @apply text-xl mx-4 py-3 bg-theme-50 my-3 px-4 rounded-lg;
    .bio-text{
      @apply flex items-center justify-between pt-2;
      .edit-outlined {
        @apply cursor-pointer text-2xl pl-2 text-Ascent2;
      }
    }
    .textArea-btn-Wrapper {
      @apply mt-4 flex items-center gap-4;
    }
  }
}

