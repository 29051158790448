.tabler_drawer_container {
  .ant-drawer-body {
    padding: 0;
  }
;

  .tabler_drawer-wrapper {
    padding: 12px 24px;
  }
}

.event-img-dimensions {
  @apply rounded-xl w-full;
  object-fit: cover;
  aspect-ratio: 16/9;
}

.flex-wrapper {
  @apply flex items-center
}

.coTabler-counter-wrapper {
  @apply bg-gray-300 grid place-items-center py-2 rounded-lg;
  width: 45px;
}

.coHost-image-wrapper {
  @apply flex;
  width: 50px;
  height: 50px;
  position: relative;

  .coHost-image-dimensions {
    height: 100%;
    width: 100%;
    border-radius: 50%;
  }

  .coHost-smallImage-dimensions {
    @apply absolute top-8 left-10;
    height: 65%;
    width: 65%;
    border-radius: 50%;
  }
}

.cohost-drawer-container {
  .ant-drawer-body {
    padding: 0;
  }

  .cohost-drawer-wrapper {
    padding: 12px 24px;
  }
}

.cohost-drawer-coHost-image-wrapper {
  width: 40px;
  height: 40px;

  .cohost-drawer-coHost-image-dimensions {
    height: 100%;
    width: 100%;
    border-radius: 50%;
  }
}
