@tailwind base;
@tailwind components;
@tailwind utilities;
@import "antd/dist/reset.css";

@layer base {
  ::-webkit-scrollbar{
    @apply w-1.5 h-1.5
  }
  ::-webkit-scrollbar-thumb{
    @apply bg-gradient-to-b;
    --tw-gradient-from: #6AA5FF var(--tw-gradient-from-position);
    --tw-gradient-to: #E02BF0 var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
    border-radius: 2px;
    -moz-border-radius: 2px;
  }
  html,
  body {
    @apply bg-slate-100 tracking-wide;
    font-size: 10px;
    color: #3f3f3f;
    #root {
      @apply h-[100vh] overflow-hidden;
    }
    .main-section {
      @apply p-8 flex flex-col gap-4 overflow-hidden;
      height: 93vh;
    }
  }


}
.ant-menu-horizontal {
  box-shadow: none;
  border-bottom: 0;
}
.ant-table-cell {
  font-size: 1.25rem;
}
.ant-btn-primary{
  background-color: #1677ff;
}
