@mixin linkStyles {
  @apply block capitalize text-inherit line-clamp-1;
  &:is(:hover, :focus, .active) {
    @apply bg-theme-100;
    @apply flex-1;
  }
}

.header {
  @apply sticky top-0 left-0 flex justify-between pl-4 items-center border-b-2 border-theme-200 bg-white;
  width: 100%;
  z-index: 99;
  height: 7vh;
  font-size: 1.4rem;

  .header-brand {
    @apply w-20 self-center p-5;

    .brand-logo {
      @apply h-auto w-full;
    }
  }

  .header-menu {
    @apply flex-1 flex items-center px-5;
    .menu {
      @apply flex items-center justify-start items-center justify-items-center justify-center gap-2 font-500;
    }

    .menu-item {
      .item-link {
        @include linkStyles;
        @apply px-6 py-8;

        &:is(:hover, :focus) {
          @apply bg-theme-100;
        }

        &.active {
          @apply bg-theme-100 relative;

          &::after {
            content: "";
            @apply absolute bottom-0 inset-x-1 h-0.5 bg-theme-900 rounded-t-full;
          }
        }
      }
    }
  }

  .header-user {
    @apply flex flex-wrap items-center cursor-pointer relative p-4;
    &:is(:hover, :focus, .active) {
      @apply bg-theme-100;

      .user-options {
        @apply flex
      }
    }

    .user-avatar {
      @apply h-14 w-14 rounded-full overflow-hidden mr-4 border-2 border-theme-200;
      .avatar-img {
        @apply h-full w-full object-cover
      }
    }

    .user-details {
      @apply w-32;

      .user-name {
        @apply font-700 line-clamp-1;
      }
    }

    .user-options {
      @apply absolute font-semibold p-2 top-full right-0 w-full w-max max-w-md bg-white py-4 px-2 border border-theme-100 hidden flex-col gap-y-2 z-10 shadow-lg;
    }

    .options {
      .option-link {
        @apply px-6 py-4;
        @include linkStyles;

        &.active {
          @apply bg-theme-100 relative;

          &::after {
            @apply absolute left-0 inset-y-2 w-1 bg-theme-900 rounded-r-full;
          }
        }
      }
    }
  }
}



