.login-container {
  @apply grid place-items-center;
  height: 100vh;
  width: 100vw;
  background-color: rgb(246, 247, 248);

  .login-container-wrapper {
    @apply flex justify-center rounded-2xl overflow-hidden;
    background-color: #fff;
    height: 60vh;
    width: 50vw;
    box-shadow: 0 1px 20px 0 rgba(0, 0, 0, 0.2);

    .login-image-container {
      @apply flex rounded-l-lg;
      flex: 0.45;
    }

    .form-container {
      @apply flex  flex-col px-10 rounded-r-lg justify-center;
      flex: 0.55;

      .form-title {
        @apply pb-6 font-600 text-4xl
      }

      .logo-container {
        @apply flex justify-center items-center pb-5
      }

      .logo {
        @apply w-32;
      }

      .form {
        @apply flex flex-col ;
      }

      .ant-input {
        height: 45px;
      }

      .ant-input-affix-wrapper .ant-input {
        height: 35px;
      }

      .fgt-pwd {
        @apply capitalize ml-auto;
        color: #1890ff;
      }

      .submit-btn {
        @apply font-semibold uppercase bg-gradient-to-r mt-5;
        height: 45px;
        color: #fff;
        --tw-gradient-from: #6AA5FF var(--tw-gradient-from-position);
        --tw-gradient-to: #E02BF0 var(--tw-gradient-to-position);
        --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
      }

    }
  }
}
