.dashboard-container {
  @apply flex flex-wrap overflow-auto p-3;
  height: 100%;
  width: 100%;

  .chart-wrapper {
    @apply drop-shadow-md;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: #fff;
    padding: 3rem;
    border-radius: 1rem;
    height: 55vh;
  }
  .stats-wrapper {
    @apply drop-shadow-md flex-1;
    background-color: #fff;
    padding: 3rem;
    border-radius: 1rem;
  }
}

