.main-section-header {
  @apply flex justify-between items-center;
}
.main-section-header-heading {
  @apply flex flex-col gap-2 text-3xl leading-normal font-semibold capitalize;
  margin-bottom: 0 !important;
}
.main-section-body {
  @apply h-full max-h-full overflow-hidden flex;
}
.shadow-card {
  @apply shadow bg-slate-50 p-4 rounded-xl;
}
